// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/companies$/)){
    let $table = $('#company-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function get_company_id(){
      let $form = $('#company-list-form');
      let list_param = $form.serializeArray();
      let company_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          company_id = list_param[i]['value'];
        };
      };
      return company_id;
    }

    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }
    set_icheck();
    set_tooltip();
    cuttable_form_controll();
    disabled_cutting_coefficient_csv_buttons();
    disabled_short_length_extra_csv_buttons();

    $('#company-pager').on('ajax:success', 'li a', function(event){
      $('#company-pager').html(event.detail[0]['paginator']);
      $('#company-list > tbody').html(event.detail[0]['companies']);
      set_icheck();
      set_tooltip();
      cuttable_form_controll();
    });

    $('#change-company').on('click', function(){
      let id = get_company_id();
      if (id == null) {
        alert('会社を選択してください');
        return false;
      }
      $.ajax({
        url: '/companies/form/' + id + '.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#company-attr-form').replaceWith(json.html);
          }
          set_tanso_setting_modal_body(json)
          set_stainless_setting_modal_body(json)
          set_cutting_coefficient_modal_body(json)
          set_short_length_extra_modal_body(json)
          set_attr_form_submit_event()
          set_icheck();
          set_tooltip();
          cuttable_form_controll();
          file_form_set();
          remove_steel_pipe_modal_error_message('#cutting-coefficient-visible-setting');
          remove_steel_pipe_modal_error_message('#short-length-extra-visible-setting');
        },
        error: function(xhr, status, e) {
          alert(status);
          set_icheck();
          set_tooltip();
          cuttable_form_controll();
          file_form_set();
        }
      });

      return false;
    });

    $('#new-company').on('click', function(){
      $.ajax({
        url: '/companies/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#company-attr-form').replaceWith(json.html);
          }
          set_attr_form_submit_event()
          clear_steel_pipe_setting_modal_and_select_box()
          clear_cuttable_pipe_setting_modal_and_select_box()
          set_icheck()
          set_tooltip()
          cuttable_form_controll()
          file_form_set()
          remove_steel_pipe_modal_error_message('#cutting-coefficient-visible-setting');
          remove_steel_pipe_modal_error_message('#short-length-extra-visible-setting');
          disabled_cutting_coefficient_csv_buttons();
          disabled_short_length_extra_csv_buttons();
        },
        error: function(xhr, status, e) {
          alert(status);
          set_icheck();
          set_tooltip();
          cuttable_form_controll();
          file_form_set();
        }
      });
      return false;
    });

    $('#delete-company').on('click', function(){
      let id = get_company_id();
      if (id == null) {
        alert('会社を選択してください');
        return false;
      }
      $.ajax({
        url: '/companies/' + id + '/users.json',
        success: function(json, status, xhr) {
          let contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#delete-company-id').val(id);
            console.log(json.users);
            if(json.users > 0) {
              $('#delete-warning').show();
            } else {
              $('#delete-warning').hide();
            }
            $('#delete-modal').modal();
            set_icheck();
            set_tooltip();
            cuttable_form_controll();
            file_form_set();
            disabled_cutting_coefficient_csv_buttons();
            disabled_short_length_extra_csv_buttons();
          }
        },
        error: function(xhr, status, e) {
          alert(status);
          set_icheck();
          set_tooltip();
          cuttable_form_controll();
          file_form_set();
        }
      });
      return false;
    });

    $('#tansokou-visible-setting').on('shown.bs.modal', function(event){
      let $list = $('#tansokou-visible-setting-list');
      $list.floatThead({
        scrollContainer: function($list){
          return $list.closest('.table-responsive');
        }
      });

      // 11桁以上入力をさせないための処理
      var target = $(event.target).find('input')

      target.each(function(index, element){
        $(element).on('input', function(event){
          var target_val = $(event.target).val()
          var digit = target_val.length
          if (digit > 11) {
            $(event.target).val(target_val.slice(0,-1))
          }
          // 先頭に0が入力された場合は0を取り除く
          if(target_val[0] === '0') {
            $(event.target).val(target_val.substring(1))
          }
        })
      })
    });

    $('#stainless-visible-setting').on('shown.bs.modal', function(event){
      let $list = $('#stainless-visible-setting-list');
      $list.floatThead({
        scrollContainer: function($list){
          return $list.closest('.table-responsive');
        }
      });

      // 11桁以上入力をさせないための処理
      var target = $(event.target).find('input')

      target.each(function(index, element){
        $(element).on('input', function(event){
          var target_val = $(event.target).val()
          var digit = target_val.length
          if (digit > 11) {
            $(event.target).val(target_val.slice(0,-1))
          }
          // 先頭に0が入力された場合は0を取り除く
          if(target_val[0] === '0') {
            $(event.target).val(target_val.substring(1))
          }
        })
      })
    });

    $('#tansokou-visible-setting').on('submit', function(){
      close_modal('#tansokou-visible-setting')
    });

    $('#stainless-visible-setting').on('submit', function(){
      close_modal('#stainless-visible-setting')
    });

    $('#tansokou_company_steelpipe_form').on('ajax:success',function(event){
      const [json, status, xhr] = event.detail
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        if (json.steel_pipe_modal_error != null){
          create_steel_pipe_modal_error_message('#tanso-visible-setting',json.steel_pipe_modal_error)
          return
        }

        remove_steel_pipe_modal_error_message('#tanso-visible-setting')
        var select = $('#company_tansokou_steel_pipe_codes')
        select.html([])
        json.tanso_pipe_settings.forEach(function(setting){
          if (setting.sort_num === 100000000000) return
          setting_value = {item_name_code: setting.item_name_code, sort_num: setting.sort_num}
          select.append($('<option>').val(JSON.stringify(setting_value)).text(setting.item_name_text))
        })

        set_tanso_setting_modal_body(json)
      }
    })
    $('#tansokou_company_steelpipe_form').on('ajax:error', function(event) {
      const [json, status, xhr] = event.detail;
      alert(status);
    });

    $('#stainless_company_steelpipe_form').on('ajax:success',function(event){
      const [json, status, xhr] = event.detail
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        if (json.steel_pipe_modal_error != null){
          create_steel_pipe_modal_error_message('#stainless-visible-setting',json.steel_pipe_modal_error)
          return
        }

        remove_steel_pipe_modal_error_message('#stainless-visible-setting');
        var select = $('#company_stainless_steel_pipe_codes')
        select.html([])
        json.stainless_pipe_settings.forEach(function(setting){
          if (setting.sort_num === 100000000000) return
          setting_value = {item_name_code: setting.item_name_code, sort_num: setting.sort_num}
          select.append($('<option>').val(JSON.stringify(setting_value)).text(setting.item_name_text))
        })

        set_stainless_setting_modal_body(json)
      }
    })
    $('#stainless_company_steelpipe_form').on('ajax:error', function(event) {
      const [json, status, xhr] = event.detail;
      alert(status);
    });

    $('#tanso_setting_cancel').on('click', function(){
      close_modal('#tansokou-visible-setting')
    })

    $('#stainless_setting_cancel').on('click', function(){
      close_modal('#stainless-visible-setting')
    });
    
    // 切断賃係数関連
    $('#cutting-coefficient-visible-setting').on('shown.bs.modal', function(event){
      let $list = $('#cutting-coefficient-visible-setting-list');
      $list.floatThead({
        scrollContainer: function($list){
          return $list.closest('.table-responsive');
        }
      });
      // 整数部2桁、小数部3桁以上入力をさせないための処理
      var target = $(event.target).find('input')
      target.each(function(index, element){
        $(element).on('input', function(event){
          if (event.target.name != "import_csv_file") {
            var target_val = $(event.target).val()
            if (target_val.match(/^\d{0,1}(\.\d{0,2})?$/) === null) {
              $(event.target).val(target_val.slice(0,-1))
            }
          }
        })
      })
    });

    // 切断賃係数モーダルのフォームsubmit時
    $('#cutting-coefficient-form').on('ajax:success',function(event){
      const [json, status, xhr] = event.detail
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        if (json.cutting_coefficient_modal_error != null){
          create_steel_pipe_modal_error_message('#cutting-coefficient-visible-setting',json.cutting_coefficient_modal_error)
          return
        }
        remove_steel_pipe_modal_error_message('#cutting-coefficient-visible-setting');
        var select = $('#company_cutting_coefficient_codes')
        select.html([])
        json.cutting_coefficient_settings.forEach(function(setting){
          setting_value = {category_text: setting.category_text, item_name_code: setting.item_name_code, item_name_text: setting.item_name_text, coefficient: setting.coefficient}
          setting_text = setting.item_name_text + "：" + setting.item_name_code + "：" + setting.coefficient
          select.append($('<option>').val(JSON.stringify(setting_value)).text(setting_text))
        })

        set_cutting_coefficient_modal_body(json)
        file_form_set()
        close_modal('#cutting-coefficient-visible-setting')
      }
    })

    // 切断賃係数CSVで取り込んだ内容反映
    $('#cutting-coefficient-csv-upload-form').on('ajax:success',function(event){
      const [json, status, xhr] = event.detail
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        if (json.cutting_coefficient_modal_error != null){
          create_steel_pipe_modal_error_message('#cutting-coefficient-visible-setting',json.cutting_coefficient_modal_error)
          return
        }
        remove_steel_pipe_modal_error_message('#cutting-coefficient-visible-setting');
        set_cutting_coefficient_modal_body(json)
        file_form_set()
      }
    })

    $('#cutting-coefficient-form').on('ajax:error', function(event) {
      const [json, status, xhr] = event.detail;
      alert(status);
    });

    $('#cutting-coefficient-setting-cancel').on('click', function(){
      close_modal('#cutting-coefficient-visible-setting')
    })

    // 短尺エキストラ関連
    $('#short-length-extra-visible-setting').on('shown.bs.modal', function(event){
      let $list = $('#short-length-extra-visible-setting-list');
      $list.floatThead({
        scrollContainer: function($list){
          return $list.closest('.table-responsive');
        }
      });
      // 整数部9桁、小数部4桁以上入力をさせないための処理
      var target = $(event.target).find('input')
      target.each(function(index, element){
        $(element).on('input', function(event){
          var target_val = $(event.target).val()
          if (target_val.match(/^\d{0,8}(\.\d{0,3})?$/) === null) {
            $(event.target).val(target_val.slice(0,-1))
          }
        })
      })
    });

    // 短尺エキストラモーダルのフォームsubmit時
    $('#short-length-extra-form').on('ajax:success',function(event){
      const [json, status, xhr] = event.detail
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        if (json.short_length_extra_modal_error != null){
          create_steel_pipe_modal_error_message('#short-length-extra-visible-setting',json.short_length_extra_modal_error)
          return
        }
        remove_steel_pipe_modal_error_message('#short-length-extra-visible-setting');
        var select = $('#company_short_length_extra_codes')
        select.html([])
        json.short_length_extra_settings.forEach(function(setting){
          setting_value = {category_text: setting.category_text, item_name_code: setting.item_name_code, item_name_text: setting.item_name_text, short_length_extra_value: setting.short_length_extra_value}
          setting_text = setting.item_name_text + "：" + setting.item_name_code + "：" + setting.short_length_extra_value
          select.append($('<option>').val(JSON.stringify(setting_value)).text(setting_text))
        })

        set_short_length_extra_modal_body(json)
        file_form_set()
        close_modal('#short-length-extra-visible-setting')
      }
    })

    // 短尺エキストラCSVで取り込んだ内容反映
    $('#short-length-extra-csv-upload-form').on('ajax:success',function(event){
      const [json, status, xhr] = event.detail
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        if (json.short_length_extra_modal_error != null){
          create_steel_pipe_modal_error_message('#short-length-extra-visible-setting',json.short_length_extra_modal_error)
          return
        }
        remove_steel_pipe_modal_error_message('#short-length-extra-visible-setting');
        set_short_length_extra_modal_body(json)
        file_form_set()
      }
    })

    $('#short-length-extra-form').on('ajax:error', function(event) {
      const [json, status, xhr] = event.detail;
      alert(status);
    });

    $('#short-length-extra-setting-cancel').on('click', function(){
      close_modal('#short-length-extra-visible-setting')
    })

    // 関数群
    function set_tanso_setting_modal_body(json){
      var modal_html = $.parseHTML(json.tanso_setting_modal)
      var body_html = $(modal_html).find('.tanso_setting_table_body').html()
      $('.tanso_setting_table_body').html(body_html)
    }

    function set_stainless_setting_modal_body(json){
      var modal_html = $.parseHTML(json.stainless_setting_modal)
      var body_html = $(modal_html).find('.stainless_setting_table_body').html()
      $('.stainless_setting_table_body').html(body_html)
    }

    function clear_steel_pipe_setting_modal_and_select_box(){
      $('#company_tansokou_steel_pipe_codes').html([])
      $('#company_stainless_steel_pipe_codes').html([])

      $('.tanso_setting_table_body').find('input.form-control').each(function(index, element){
        $(element).val("")
      })
      $('.stainless_setting_table_body').find('input.form-control').each(function(index, element){
        $(element).val("")
      })
    }

    function remove_steel_pipe_modal_error_message(selecter){
      if ($(selecter).find('.callout.callout-danger').length == 0) return;

      $(selecter).find('.callout.callout-danger').remove()
      if (selecter === '#cutting-coefficient-visible-setting' || selecter === '#short-length-extra-visible-setting') {
        $(selecter).find('.modal-content').css('height', '700px')
      } else {
        $(selecter).find('.modal-content').css('height', '600px')
      }
    }

    function create_steel_pipe_modal_error_message(selecter, message){
      //既にエラーメッセージがあれば何もしない
      if ($(selecter).find('.callout.callout-danger').length != 0) return;

      var modal_body = $(selecter).find('.modal-body')
      var error_html = $('<div>').attr('class', 'callout callout-danger').html(`<p>${message}</p>`)
      modal_body.prepend(error_html)
      if (message.includes('CSV') || message.includes('先に') || message.includes('整数部')){
        $(selecter).find('.modal-content').css('height', '750px')
      } else {
        $(selecter).find('.modal-content').css('height', '670px')
      }
      
    }

    function all_selected_item(selecter){
      $(selecter).find('option').each(function(index, element){
        $(element).prop('selected',true)
      })
    }

    function close_modal(selecter){
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove()
      $(selecter).modal('hide')
    }

    function set_cutting_coefficient_modal_body(json){
      var modal_html = $.parseHTML(json.cutting_coefficient_setting_modal)
      var body_html = $(modal_html).find('.cutting_coefficient_setting_table_body').html()
      var over_view_html = $(modal_html).find('#cutting-coefficient-over-view').html()
      var csv_export_button = $(modal_html).find('#cutting-coefficient-csv-export-button').html()
      var csv_import_html = $(modal_html).find('#cutting-coefficient-csv-upload-form').html()
      $('.cutting_coefficient_setting_table_body').html(body_html)
      $('#cutting-coefficient-over-view').html(over_view_html)
      $('#cutting-coefficient-csv-export-button').html(csv_export_button)
      $('#cutting-coefficient-csv-upload-form').html(csv_import_html)
      $('#coefficient-csv-text').val('')
      disabled_cutting_coefficient_csv_buttons();
    }

    function set_short_length_extra_modal_body(json){
      var modal_html = $.parseHTML(json.short_length_extra_setting_modal)
      var body_html = $(modal_html).find('.short_length_extra_setting_table_body').html()
      var over_view_html = $(modal_html).find('#short-length-extra-over-view').html()
      var csv_export_button = $(modal_html).find('#short-length-extra-csv-export-button').html()
      var csv_import_html = $(modal_html).find('#short-length-extra-csv-upload-form').html()
      $('.short_length_extra_setting_table_body').html(body_html)
      $('#short-length-extra-over-view').html(over_view_html)
      $('#short-length-extra-csv-export-button').html(csv_export_button)
      $('#short-length-extra-csv-upload-form').html(csv_import_html)
      $('#extra-csv-text').val('')
      disabled_short_length_extra_csv_buttons();
    }

    function disabled_cutting_coefficient_csv_buttons(){
      if ($('.cutting_coefficient_setting_table_body').find('td').length === 0){
        $('#coefficient-csv-import-button-hidden').prop('disabled', true);
        $('#coefficient-csv-select-button').prop('disabled', true);
        $('#coefficient-csv-export-button-hidden').prop('disabled', true);
        $('#coefficient-csv-text').prop('disabled', true);
        $('#coefficient-csv-text-icon').prop('disabled', true);
        $('#coefficient-csv-import-button').prop('disabled', true);
        $('#coefficient-csv-export-button').prop('disabled', true);
      } else {
        $('#coefficient-csv-import-button-hidden').prop('disabled', false);
        $('#coefficient-csv-select-button').prop('disabled', false);
        $('#coefficient-csv-export-button-hidden').prop('disabled', false);
        $('#coefficient-csv-text').prop('disabled', false);
        $('#coefficient-csv-text-icon').prop('disabled', false);
        $('#coefficient-csv-import-button').prop('disabled', false);
        $('#coefficient-csv-export-button').prop('disabled', false);
      }
    }

    function disabled_short_length_extra_csv_buttons(){
      if ($('.short_length_extra_setting_table_body').find('td').length === 0){
        $('#extra-csv-import-button-hidden').prop('disabled', true);
        $('#extra-csv-select-button').prop('disabled', true);
        $('#extra-csv-export-button-hidden').prop('disabled', true);
        $('#extra-csv-text').prop('disabled', true);
        $('#extra-csv-text-icon').prop('disabled', true);
        $('#extra-csv-import-button').prop('disabled', true);
        $('#extra-csv-export-button').prop('disabled', true);
      } else {
        $('#extra-csv-import-button-hidden').prop('disabled', false);
        $('#extra-csv-select-button').prop('disabled', false);
        $('#extra-csv-export-button-hidden').prop('disabled', false);
        $('#extra-csv-text').prop('disabled', false);
        $('#extra-csv-text-icon').prop('disabled', false);
        $('#extra-csv-import-button').prop('disabled', false);
        $('#extra-csv-export-button').prop('disabled', false);
      }
    }

    function clear_cuttable_pipe_setting_modal_and_select_box(){
      $('#company_cutting_coefficient_codes').html([])
      $('#company_short_length_extra_codes').html([])
      $('#coefficient-csv-select-button').val('')
      $('#coefficient-csv-text').val('')
      $('#extra-csv-select-button').val('')
      $('#extra-csv-text').val('')

      $('.cutting_coefficient_setting_table_body').html([])
      $('#cutting-coefficient-over-view').html('<p id="cutting-coefficient-over-view">　以下入力中の会社マスタに対して表示する品名規格のうち、切断対応可能な品名規格の一覧です。 </p>')
      $('.short_length_extra_setting_table_body').html([])
      $('#short-length-extra-over-view').html('<p id="cutting-coefficient-over-view">　以下入力中の会社マスタに対して表示する品名規格のうち、切断対応可能な品名規格の一覧です。 </p>')
    }

    // 切断可フラグ関連の入力フォーム制御
    function cuttable_form_controll() {
      if ($('#is_cuttable_check').is(':checked')) {
        $('.cuttable_form').prop('readonly', false);
        $('#cutting-coefficient-setting-button').prop('disabled', false);
        $('#short-length-extra-button').prop('disabled', false);
      } else {
        $('.cuttable_form').prop('readonly', true);
        $('#cutting-coefficient-setting-button').prop('disabled', true);
        $('#short-length-extra-button').prop('disabled', true);
      }

      $('#is_cuttable_check').on('ifChanged', function(event){
        if ($(this).prop('checked')) {
          $('.cuttable_form').prop('readonly', false);
          $('#cutting-coefficient-setting-button').prop('disabled', false);
          $('#short-length-extra-button').prop('disabled', false);
        } else {
          $('.cuttable_form').prop('readonly', true);
          $('#cutting-coefficient-setting-button').prop('disabled', true);
          $('#short-length-extra-button').prop('disabled', true);
        }
      });
    }

    // Edgeでselectedの背景色をcssで変更することができないので
    // submit時にselectedとする処理を行う
    function set_attr_form_submit_event(){
      $('#company-attr-form').on('submit',function(event){
        all_selected_item('#company_tansokou_steel_pipe_codes')
        all_selected_item('#company_stainless_steel_pipe_codes')
        all_selected_item('#company_cutting_coefficient_codes')
        all_selected_item('#company_short_length_extra_codes')
        all_selected_item('#company_rounding_params_codes')

        this.submit()
      })
    }
    set_attr_form_submit_event()

    // ツールチップを有効化する
    function set_tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    }
  
    // 画面内でのエンターキーを無効化する
    document.onkeypress = function(e) {
      if (e.key === 'Enter') {
        return false;
      }
    }

    // モーダル内のhiddenのフォームへクリックを渡す
    $('#coefficient-csv-text-icon').click(function() {
      $('#coefficient-csv-select-button').click();
    });

    $('#coefficient-csv-text').click(function() {
      $('#coefficient-csv-select-button').click();
    });

    $('#coefficient-csv-import-button').click(function() {
      $('#coefficient-csv-import-button-hidden').click();
    });

    $('#coefficient-csv-export-button').click(function() {
      $('#coefficient-csv-export-button-hidden').click();
    });

    $('#extra-csv-text-icon').click(function() {
      $('#extra-csv-select-button').click();
    });

    $('#extra-csv-text').click(function() {
      $('#extra-csv-select-button').click();
    });

    $('#extra-csv-import-button').click(function() {
      $('#extra-csv-import-button-hidden').click();
    });

    $('#extra-csv-export-button').click(function() {
      $('#extra-csv-export-button-hidden').click();
    });
    
    // モーダル内のファイル選択フォームへ選択したファイルを渡す
    function file_form_set(){
      $('input[id=coefficient-csv-select-button]').change(function() {
        $('#coefficient-csv-text').val($(this).val().replace("C:\\fakepath\\", ""));
      });

      $('input[id=extra-csv-select-button]').change(function() {
        $('#extra-csv-text').val($(this).val().replace("C:\\fakepath\\", ""));
      });
    }
  }
});
